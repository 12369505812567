import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
@Pipe({
  name: 'datePassed',
  standalone: true,
})
export class DatePassedPipe implements PipeTransform {
  transform(currentDate: Date | undefined | null) {
    if (!currentDate) return;

    const now = DateTime.local().startOf('day');

    const inputDate = DateTime.fromJSDate(currentDate, {
      zone: 'local',
    }).startOf('day');

    return inputDate < now;
  }
}
